import { type LoaderFunctionArgs } from '@remix-run/node'
import { Outlet } from '@remix-run/react'
import { typedjson, useTypedLoaderData } from 'remix-typedjson'
import { getUserFromSession } from '~/utils/session.server.ts'
import { Footer } from './components/footer.tsx'
import { Header } from './components/header.tsx'

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const user = await getUserFromSession(request)
	return typedjson({ user })
}

export default function Landing() {
	const { user } = useTypedLoaderData<typeof loader>()

	return (
		<div className="">
			<Header user={user} />
			<div className="mb-16 mt-12 min-h-screen pb-16 lg:mt-20 2xl:mt-24 3xl:mt-28 4xl:mt-32 5xl:mt-36">
				<Outlet />
			</div>
			<Footer />
		</div>
	)
}
